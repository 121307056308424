<template>
  <div class="container">
    <video width="1920" height="1080" autoplay loop muted="muted">
      <source src='../assets/images/index/footer.mp4' type="video/mp4" >
      您的浏览器不支持 HTML5 video 标签。
    </video>
    <video id="video" v-if="!showFooter" width="1920" height="1080" autoplay muted="muted">
      <source src='../assets/images/index/bg.mp4' type="video/mp4" >
      您的浏览器不支持 HTML5 video 标签。
    </video>
    <headers :titles="titles"></headers>
    <footers :bottomTitles="bottomTitles"></footers>
    <div class="column392">
      <div class="column-item date">
        <times></times>
        <div class="btn cursor" @click="gotoHome">首 页</div>
      </div>
      <div class="column-item" style="height:200px">
        <div class="column-item-title title-bg">
          <h4>实时监控数据</h4>
        </div>
        <div class="monitor">
          <div class="monitor-item">
            <div class="font-num num">1105685<span>元</span></div>
            <div class="title">今日网络零售额</div>
          </div>
          <div class="monitor-item">
            <div class="font-num num">753595<span>元</span></div>
            <div class="title">今日农产品网络零售额</div>
          </div>
          <div class="monitor-item">
            <div class="font-num num">45671<span>元</span></div>
            <div class="title">近30分钟增加</div>
          </div>
          <div class="monitor-item">
            <div class="font-num num">31892<span>元</span></div>
            <div class="title">近30分钟增加</div>
          </div>
        </div>
      </div>
      <div class="column-item" style="height:240px">
        <div class="column-item-title title-bg">
          <h4>交易城市排名</h4>
        </div>
        <div class="charts">
          <v-chart :options="optionbar" />
        </div>
      </div>
      <div class="column-item" style="height:240px">
        <div class="column-item-title title-bg">
          <h4>交易类目排名</h4>
        </div>
        <div class="charts">
          <v-chart :options="optionbar2" />
        </div>
      </div>
      <div class="column-item" style="height:240px">
        <div class="column-item-title title-bg">
          <h4>{{chartTitle1}}</h4>
        </div>
        <div class="chartsList">

          <el-carousel trigger="click" height="210px" arrow="never" :interval="6000" @change="changeCharts1">
            <el-carousel-item>
              <v-chart :options="optionpie11" />
            </el-carousel-item>
            <el-carousel-item>
              <v-chart :options="optionpie22" />
            </el-carousel-item>
          </el-carousel>

        </div>
      </div>
    </div>
    <div class="column392">
      <div class="column-item btn-box">
        <div class="btn">直播数据</div>
        <div class="btn">溯源数据</div>
      </div>
      <div class="column-item" style="height:300px">
        <div class="column-item-title title-bg">
          <h4>实时监控店铺</h4>
        </div>

        <div class="scroll-div">
          <div class="table">
            <div class="table-thead">
              <div class="table-head-tr">
                <div class="table-head-th row1">店铺名称</div>
                <div class="table-head-th row2">店铺类型</div>
              </div>
            </div>
            <div class="table-tbody" @click="openShop">
              <vue-seamless-scroll :data="List" class="seamless-warp" :class-option="classOption">
                <div class="table-tbody-tr cursor" v-for="(item, index) in List" :key='index' :id="item.link">
                  <div class="table-tbody-td row1 cursor" :id="item.link">{{item.name}}</div>
                  <div class="table-tbody-td row2 cursor" :id="item.link">{{item.type}}</div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
      <div class="column-item" style="height:300px">
        <div class="column-item-title title-bg">
          <h4>TOP10人气商品排行榜</h4>
        </div>
        <div class="scroll-div">
          <div class="table1">
            <div class="table-thead">
              <div class="table-head-tr">
                <div class="table-head-th row1">排名</div>
                <div class="table-head-th row2">商品名称</div>
                <div class="table-head-th row3">订单量</div>
              </div>
            </div>
            <div class="table-tbody">
              <vue-seamless-scroll :data="List2" class="seamless-warp" :class-option="classOption">
                <div class="table-tbody-tr" v-for="(item, index) in List2" :key='index'>
                  <div class="table-tbody-td row1">{{index + 1}}</div>
                  <div class="table-tbody-td row2">{{item.name}}</div>
                  <div class="table-tbody-td row3">{{item.num}}</div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
      <div class="column-item" style="height:280px">
        <div class="column-item-title title-bg">
          <h4>TOP10店铺零售排行榜</h4>
        </div>
        <div class="scroll-div">
          <div class="table1">
            <div class="table-thead">
              <div class="table-head-tr">
                <div class="table-head-th row1">排名</div>
                <div class="table-head-th row2">店铺名称</div>
                <div class="table-head-th row3">零售额</div>
              </div>
            </div>
            <div class="table-tbody">
              <vue-seamless-scroll :data="List3" class="seamless-warp" :class-option="classOption">
                <div class="table-tbody-tr" v-for="(item, index) in List3" :key='index'>
                  <div class="table-tbody-td row1">{{index + 1}}</div>
                  <div class="table-tbody-td row2">{{item.name}}</div>
                  <div class="table-tbody-td row3">{{item.num}}</div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column850">
      <div class="charts1">
        <div class="charts1-item">
          <img src="../assets/images/index/icon10.png" />
          <div class="font-num num">{{baseInfo.shopNum}}</div>
          <div class="charts1-item-title">线上店铺总数(家)</div>
        </div>
        <div class="charts1-item">
          <img src="../assets/images/index/icon11.png" />
          <div class="font-num num">{{baseInfo.shopNew}}</div>
          <div class="charts1-item-title">本月新增店铺(家)</div>
        </div>
        <div class="charts1-item">
          <img src="../assets/images/index/icon12.png" />
          <div class="font-num num">{{baseInfo.productNum}}</div>
          <div class="charts1-item-title">线上产品总数(个)</div>
        </div>
        <div class="charts1-item">
          <img src="../assets/images/index/icon13.png" />
          <div class="font-num num">{{baseInfo.productNew}}</div>
          <div class="charts1-item-title">本月新增产品(个)</div>
        </div>
        <div class="charts1-item">
          <img src="../assets/images/index/icon14.png" />
          <div class="font-num num">{{baseInfo.averagePrice}}</div>
          <div class="charts1-item-title">平均客单价(元)</div>
        </div>
      </div>
    </div>
    <div class="column1000">
      <div class="charts2">
        <div class="charts2-item cursor" @click="showBox(1)">
          <img src="../assets/images/index/icon15.png" />
          <div>
            <div class="font-num num">{{ baseInfo.countyTurnover }}</div>
            <div class="charts2-item-title">全县网络零售额</div>
          </div>
        </div>
        <div class="charts2-item cursor" @click="showBox(2)">
          <img src="../assets/images/index/icon16.png" />
          <div>
            <div class="font-num num">{{ baseInfo.countyTurnoverIncrease }}</div>
            <div class="charts2-item-title">同比增长</div>
          </div>
        </div>
        <div class="charts2-item cursor" @click="showBox(3)">
          <img src="../assets/images/index/icon17.png" />
          <div>
            <div class="font-num num">{{ baseInfo.countyProduce }}</div>
            <div class="charts2-item-title">全县农产品网络零售额(件)</div>
          </div>
        </div>
        <div class="charts2-item cursor" @click="showBox(4)">
          <img src="../assets/images/index/icon18.png" />
          <div>
            <div class="font-num num">{{ baseInfo.countyProduceIncrease }}</div>
            <div class="charts2-item-title">同比增长</div>
          </div>
        </div>
      </div>
    </div>
    <div class="column1050">
      <div class="popup-box">
        <div class="popup-box-item" v-show="showBoxes1">
          <div class="close-box" @click="close1"></div>
          <div class="charts">
            <v-chart :options="optionbar6" />
          </div>
        </div>
        <div class="popup-box-item" v-show="showBoxes2">
          <div class="close-box" @click="close2"></div>
          <div class="charts">
            <v-chart :options="optionline6" />
          </div>
        </div>
        <div class="popup-box-item" v-show="showBoxes3">
          <div class="close-box" @click="close3"></div>
          <div class="charts">
            <v-chart :options="optionbar7" />
          </div>
        </div>
        <div class="popup-box-item" v-show="showBoxes4">
          <div class="close-box" @click="close4"></div>
          <div class="charts">
            <v-chart :options="optionline7" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import headers from "@/components/header.vue";
  import footers from "@/components/footer.vue";
  import ECharts from "vue-echarts";
  import times from "@/components/time.vue";
  import "echarts/lib/chart/pie";
  import "echarts/lib/chart/bar";
  export default {
    name: "Transaction",
    components: {
      headers,
      footers,
      times,
      "v-chart": ECharts,
    },
    data() {
      return {
        List: [],
        List2: [],
        List3: [],
        showFooter: false,
        showBoxes1: false,
        showBoxes2: false,
        showBoxes3: false,
        showBoxes4: false,
        titles: "永嘉县农村电商交易大数据",
        bottomTitles: "全县店铺详情",
        chartTitle1: '客户人群占比',
        data_bar1: [{
            value: 790,
            name: "广州"
          },
          {
            value: 380,
            name: "台州"
          },
          {
            value: 550,
            name: "温州"
          },
          {
            value: 620,
            name: "苏州"
          },
          {
            value: 580,
            name: "杭州"
          },
        ],
        data_bar2: [{
            value: 790,
            name: "农产品"
          },
          {
            value: 380,
            name: "果干类"
          },
          {
            value: 550,
            name: "服饰类"
          },
          {
            value: 620,
            name: "手工艺品"
          },
          {
            value: 790,
            name: "其他"
          },
        ],
        //交易类目排名
        optionbar2: {},
        //饼图
        optionpie11: {},
        optionpie22: {},
        baseInfo: {
          countyTurnover: 0, // 全县网络销售额
          countyTurnoverIncrease: 0, // 全县网络销售额同比增长
          countyProduce: 0, // 全县农产品网络零售额
          countyProduceIncrease: 0, // 全县农产品网络零售额同比增长
          averagePrice: 0,
          shopNew: 0,
          shopNum: 0,
          productNew: 0,
          productNum: 0,
        },
        optionbar6: {},
        optionbar7: {},
        optionline6: {},
        optionline7: {},
        optionbar: {}
      };
    },
    computed: {
      classOption() {
        return {
          step: .5, // 数值越大速度滚动越快
          // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          // openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        };
      }
    },
    mounted() {
      this.getyjdealcity() // 交易城市排名
      this.getyjproductcategory() // 交易类目排名 、产品类目占比
      this.getyjcustomercrowd() // 客户人群占比
      this.getObjByMenu() // 顶部基本信息
      this.getyjshop() // 近日网络销售额
      this.getyjgoods() // TOP10人气
      this.getyjcountymonth() // 电商站点交易明细
      this.getyjdealamplitude() // 配送数量增幅
      this.getyjproducemonth() // 电商站点交易明细
      this.getyjproduceamplitude() // 配送数量增幅
      this.init()
  },
  methods: {
      init() {
        const videoDom = document.getElementById('video')
        videoDom.addEventListener('ended', () => {
          this.showFooter = true
        }, false)
      },
      getyjcountymonth() {
        const year = new Date().getFullYear() + '-01-01 00:00:00'
        const lastYear = (new Date().getFullYear() - 1) + '-01-01 00:00:00'
        this.$api.get('/yongjia-biz/yjcountymonth/page?current=1&size=1&year=' + year)
         .then(({ data }) => {
           this.$api.get('/yongjia-biz/yjcountymonth/page?current=1&size=1&year=' + lastYear)
            .then(res => {
              const result = (data.records || [])[0] || {}
              const { january, february, march, april, may, june, july, october, september, august, november, december } = result
              const currentData = [+january, +february, +march, +april, +may, +june, +july, +august, +september, +october, +november, +december]
              const lastYearResult = (res.data.records || [])[0] || {}
              const lastYearData = [+lastYearResult.january, +lastYearResult.february, +lastYearResult.march, +lastYearResult.april, +lastYearResult.may, +lastYearResult.june, +lastYearResult.july, +lastYearResult.august, +lastYearResult.september, +lastYearResult.october, +lastYearResult.november, +lastYearResult.december]
              this.optionbar6 = {
                title: {
                  show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
                  text: '电商站点交易额明细（万）', //主标题文本，'\n'指定换行
                  x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
                  y: 'top', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
                  textAlign: null, //水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center
                  backgroundColor: 'rgba(0,0,0,0)', //标题背景颜色，默认'rgba(0,0,0,0)'透明
                  borderColor: '#ccc', //标题边框颜色,默认'#ccc'
                  borderWidth: 0, //标题边框线宽，单位px，默认为0（无边框）
                  padding: [17, 0, 0, 0], //标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
                  textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                    fontSize: 20,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    color: "#fff"
                  },
                },
                grid: {
                  top: '25%', //距上边距
                  left: '10%', //距离左边距
                  right: '10%', //距离右边距
                  bottom: "15%", //距离下边距
                },
                legend: {
                  top: 40,
                  right: "10%",
                  textStyle: {
                    //图例文字的样式
                    color: "#fff",
                    fontSize: 15,
                  },
                },
                xAxis: [{
                  type: "category",
                  data: [
                    "1月",
                    "2月",
                    "3月",
                    "4月",
                    "5月",
                    "6月",
                    "7月",
                    "8月",
                    "9月",
                    "10月",
                    "11月",
                    "12月",
                  ],
                  // 修改文本的颜色
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改轴线的颜色
                }, ],
                yAxis: [{
                  type: "value",
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改y轴横向分割线的颜色
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      color: ["#5f666a"],
                    },
                  },
                }, ],
                series: [{
                    name: "去年",
                    type: "bar",
                    barWidth: "30%",

                    itemStyle: {
                      normal: {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        barBorderRadius: [20, 20, 0, 0],
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                            offset: 0,
                            color: "#111",
                          },
                          {
                            offset: 1,
                            color: "#CBB012",
                          },
                        ]),
                      },
                    },
                    data: lastYearData,
                  },
                  {
                    name: "今年",
                    type: "bar",
                    itemStyle: {
                      normal: {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        barBorderRadius: [20, 20, 0, 0],
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                            offset: 0,
                            color: "#111",
                          },
                          {
                            offset: 1,
                            color: "#46F9FD",
                          },
                        ]),
                      },
                    },
                    barWidth: "30%",
                    data: currentData,
                  },
                ],
              }
            })
         })
      },
      getyjproducemonth() {
        const year = new Date().getFullYear() + '-01-01 00:00:00'
        const lastYear = (new Date().getFullYear() - 1) + '-01-01 00:00:00'
        this.$api.get('/yongjia-biz/yjproducemonth/page?current=1&size=1&year=' + year)
         .then(({ data }) => {
           this.$api.get('/yongjia-biz/yjproducemonth/page?current=1&size=1&year=' + lastYear)
            .then(res => {
              const result = (data.records || [])[0] || {}
              const { january, february, march, april, may, june, july, october, september, august, november, december } = result
              const currentData = [+january, +february, +march, +april, +may, +june, +july, +august, +september, +october, +november, +december]
              const lastYearResult = (res.data.records || [])[0] || {}
              const lastYearData = [+lastYearResult.january, +lastYearResult.february, +lastYearResult.march, +lastYearResult.april, +lastYearResult.may, +lastYearResult.june, +lastYearResult.july, +lastYearResult.august, +lastYearResult.september, +lastYearResult.october, +lastYearResult.november, +lastYearResult.december]
              this.optionbar7 = {
                title: {
                  show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
                  text: '电商农产品交易额明细（万）', //主标题文本，'\n'指定换行
                  x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
                  y: 'top', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
                  textAlign: null, //水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center
                  backgroundColor: 'rgba(0,0,0,0)', //标题背景颜色，默认'rgba(0,0,0,0)'透明
                  borderColor: '#ccc', //标题边框颜色,默认'#ccc'
                  borderWidth: 0, //标题边框线宽，单位px，默认为0（无边框）
                  padding: [20, 0, 0, 0], //标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
                  textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                    fontSize: 20,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    color: "#fff"
                  },
                },
                grid: {
                  top: '25%', //距上边距
                  left: '10%', //距离左边距
                  right: '10%', //距离右边距
                  bottom: "15%", //距离下边距
                },
                legend: {
                  top: 40,
                  right: "10%",
                  textStyle: {
                    //图例文字的样式
                    color: "#fff",
                    fontSize: 15,
                  },
                },
                xAxis: [{
                  type: "category",
                  data: [
                    "1月",
                    "2月",
                    "3月",
                    "4月",
                    "5月",
                    "6月",
                    "7月",
                    "8月",
                    "9月",
                    "10月",
                    "11月",
                    "12月",
                  ],
                  // 修改文本的颜色
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改轴线的颜色
                }, ],
                yAxis: [{
                  type: "value",
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改y轴横向分割线的颜色
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      color: ["#5f666a"],
                    },
                  },
                }, ],
                series: [{
                    name: "去年",
                    type: "bar",
                    barWidth: "30%",

                    itemStyle: {
                      normal: {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        barBorderRadius: [20, 20, 0, 0],
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                            offset: 0,
                            color: "#111",
                          },
                          {
                            offset: 1,
                            color: "#62DEAF",
                          },
                        ]),
                      },
                    },
                    data: lastYearData
                  },
                  {
                    name: "今年",
                    type: "bar",
                    itemStyle: {
                      normal: {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        barBorderRadius: [20, 20, 0, 0],
                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                            offset: 0,
                            color: "#111",
                          },
                          {
                            offset: 1,
                            color: "#9C74EE",
                          },
                        ]),
                      },
                    },
                    barWidth: "30%",
                    data: currentData
                  },
                ],
              }
            })
         })
      },
      getyjproduceamplitude() {
        const year = new Date().getFullYear() + '-01-01 00:00:00'
        const lastYear = (new Date().getFullYear() - 1) + '-01-01 00:00:00'
        this.$api.get('/yongjia-biz/yjproduceamplitude/page?current=1&size=1&year=' + year)
         .then(({ data }) => {
           this.$api.get('/yongjia-biz/yjproduceamplitude/page?current=1&size=1&year=' + lastYear)
            .then(res => {
              const result = (data.records || [])[0] || {}
              const { january, february, march, april, may, june, july, october, september, august, november, december } = result
              const currentData = [+january, +february, +march, +april, +may, +june, +july, +august, +september, +october, +november, +december]
              const lastYearResult = (res.data.records || [])[0] || {}
              const lastYearData = [+lastYearResult.january, +lastYearResult.february, +lastYearResult.march, +lastYearResult.april, +lastYearResult.may, +lastYearResult.june, +lastYearResult.july, +lastYearResult.august, +lastYearResult.september, +lastYearResult.october, +lastYearResult.november, +lastYearResult.december]
              this.optionline7 = {
                title: {
                  show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
                  text: '农产品交易额增幅（万）', //主标题文本，'\n'指定换行
                  x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
                  y: 'top', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
                  textAlign: null, //水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center
                  backgroundColor: 'rgba(0,0,0,0)', //标题背景颜色，默认'rgba(0,0,0,0)'透明
                  borderColor: '#ccc', //标题边框颜色,默认'#ccc'
                  borderWidth: 0, //标题边框线宽，单位px，默认为0（无边框）
                  padding: [20, 0, 0, 0], //标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
                  textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                    fontSize: 20,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    color: "#fff"
                  },
                },
                grid: {
                  top: '25%', //距上边距
                  left: '10%', //距离左边距
                  right: '10%', //距离右边距
                  bottom: "15%", //距离下边距
                },
                legend: {
                  top: 40,
                  right: "10%",
                  icon: "roundRect",
                  textStyle: {
                    //图例文字的样式
                    color: "#fff",
                    fontSize: 15,
                  },
                },
                xAxis: [{
                  type: "category",
                  data: [
                    "1月",
                    "2月",
                    "3月",
                    "4月",
                    "5月",
                    "6月",
                    "7月",
                    "8月",
                    "9月",
                    "10月",
                    "11月",
                    "12月",
                  ],
                  // 修改文本的颜色
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改轴线的颜色
                }, ],
                yAxis: [{
                  type: "value",
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改y轴横向分割线的颜色
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      color: ["#5f666a"],
                    },
                  },
                }, ],
                series: [{
                    name: "去年",
                    type: "line",
                    smooth: true,
                    color: ["#62DEAF"],
                    lineStyle: {
                      width: 5,
                    },
                    data: lastYearData
                  },
                  {
                    name: "今年",
                    type: "line",
                    smooth: true,
                    color: ["#9C74EE"],
                    lineStyle: {
                      width: 5,
                    },
                    data: currentData
                  },
                ],
              }
            })
         })
      },
      getyjdealamplitude() {
        const year = new Date().getFullYear() + '-01-01 00:00:00'
        const lastYear = (new Date().getFullYear() - 1) + '-01-01 00:00:00'
        this.$api.get('/yongjia-biz/yjdealamplitude/page?current=1&size=1&year=' + year)
         .then(({ data }) => {
           this.$api.get('/yongjia-biz/yjdealamplitude/page?current=1&size=1&year=' + lastYear)
            .then(res => {
              const result = (data.records || [])[0] || {}
              const { january, february, march, april, may, june, july, october, september, august, november, december } = result
              const currentData = [+january, +february, +march, +april, +may, +june, +july, +august, +september, +october, +november, +december]
              const lastYearResult = (res.data.records || [])[0] || {}
              const lastYearData = [+lastYearResult.january, +lastYearResult.february, +lastYearResult.march, +lastYearResult.april, +lastYearResult.may, +lastYearResult.june, +lastYearResult.july, +lastYearResult.august, +lastYearResult.september, +lastYearResult.october, +lastYearResult.november, +lastYearResult.december]
              this.optionline6 = {
                title: {
                  show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
                  text: '交易额增幅（万）', //主标题文本，'\n'指定换行
                  x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
                  y: 'top', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
                  textAlign: null, //水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center
                  backgroundColor: 'rgba(0,0,0,0)', //标题背景颜色，默认'rgba(0,0,0,0)'透明
                  borderColor: '#ccc', //标题边框颜色,默认'#ccc'
                  borderWidth: 0, //标题边框线宽，单位px，默认为0（无边框）
                  padding: [20, 0, 0, 0], //标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
                  textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                    fontSize: 20,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    color: "#fff"
                  },
                },
                grid: {
                  top: '25%', //距上边距
                  left: '10%', //距离左边距
                  right: '10%', //距离右边距
                  bottom: "15%", //距离下边距
                },
                legend: {
                  top: 40,
                  right: "10%",
                  icon: "roundRect",
                  textStyle: {
                    //图例文字的样式
                    color: "#fff",
                    fontSize: 15,
                  },
                },
                xAxis: [{
                  type: "category",
                  data: [
                    "1月",
                    "2月",
                    "3月",
                    "4月",
                    "5月",
                    "6月",
                    "7月",
                    "8月",
                    "9月",
                    "10月",
                    "11月",
                    "12月",
                  ],
                  // 修改文本的颜色
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改轴线的颜色
                }, ],
                yAxis: [{
                  type: "value",
                  axisLabel: {
                    color: "#556f95",
                    fontSize: 10,
                  },
                  // 修改y轴横向分割线的颜色
                  splitLine: {
                    lineStyle: {
                      type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                      color: ["#5f666a"],
                    },
                  },
                }, ],
                series: [{
                    name: "去年",
                    type: "line",
                    smooth: true,
                    color: ["#CBB012"],
                    lineStyle: {
                      width: 5,
                    },
                    data: lastYearData
                  },
                  {
                    name: "今年",
                    type: "line",
                    smooth: true,
                    color: ["#46F9FD"],
                    lineStyle: {
                      width: 5,
                    },
                    data: currentData
                  },
                ],
              }
            })
         })
      },
      getyjdealcity() {
        this.$api.get('/yongjia-biz/yjdealcity/page?current=1&size=5')
         .then(({ data }) => {
           const info = data.records || []
           this.optionbar = ({
             grid: {
               top: "10%", //距上边距
               left: "15%", //距离左边距
               right: "15%", //距离右边距
               bottom: "15%", //距离下边距
             },
             xAxis: [{
               type: "value",
               // 修改文本的颜色
               axisLabel: {
                 color: "#fff",
                 fontSize: 10,
               },
               axisLine: {
                 show: false, //不显示坐标轴轴线
               },
               // 修改轴线的颜色
               splitLine: {
                 lineStyle: {
                   type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                   color: ["#5f666a"],
                 },
               },
             }, ],
             yAxis: [{
               type: "category",
               data: info.map(item => item.name),
               axisLine: {
                 show: false, //不显示坐标轴轴线
               },
               axisLabel: {
                 color: "#556f95",
                 fontSize: 14,
               },
               // 修改y轴横向分割线的颜色
               splitLine: {
                 lineStyle: {
                   type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                   color: ["#5f666a"],
                 },
               },
             }, ],
             series: [{
               type: "bar",
               barWidth: "30%",

               itemStyle: {
                 normal: {
                   //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                   barBorderRadius: [0, 20, 20, 0],
                   color: new ECharts.graphic.LinearGradient(0, 0, 1, 0, [{
                       offset: 0,
                       color: "#111",
                     },
                     {
                       offset: 1,
                       color: "#46F9FD",
                     },
                   ]),
                 },
               },
               data: info.map(item => item.number),
             }, ],
           })
         })
      },
      getyjproductcategory() {
        this.$api.get('/yongjia-biz/yjproductcategory/page?current=1&size=7')
         .then(({ data }) => {
           const info = data.records || []
           this.optionbar2 = ({
             grid: {
               top: "0%", //距上边距
               left: "20%", //距离左边距
               right: "20%", //距离右边距
               bottom: "5%", //距离下边距
             },

             xAxis: [{
               type: "value",

               // 修改文本的颜色

               show: false,
               splitLine: {
                 show: false,
               },
               // 修改轴线的颜色
               axisLabel: {
                 formatter: "{value} ",
               },
             }, ],
             yAxis: [{
               type: "category",
               data: info.filter((item, idx) => idx < 5).map(item => item.name),
               axisLabel: {
                 color: "#556f95",
                 fontSize: 14,
               },
               axisLine: {
                 show: false, //不显示坐标轴轴线
               },
             }, ],
             series: [{
               type: "bar",
               barWidth: "35%",

               itemStyle: {
                 normal: {
                   label: {
                     show: true, //开启显示
                     position: "right", //在上方显示
                     textStyle: {
                       //数值样式
                       color: "#fff",
                       fontSize: 12,
                     },
                     formatter: function (params) {
                       //核心部分 formatter 可以为字符串也可以是回调
                       if (params.value) {
                         //如果当前值存在则拼接
                         return params.value + "万元";
                       } else {
                         //否则返回个空
                         return "";
                       }
                     },
                   },

                   //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                   barBorderRadius: [0, 20, 20, 0],
                   color: new ECharts.graphic.LinearGradient(0, 0, 1, 0, [{
                       offset: 0,
                       color: "#111",
                     },
                     {
                       offset: 1,
                       color: "#1FB5FC",
                     },
                   ]),
                 },
               },
               data: info.filter((item, idx) => idx < 5).map(item => item.quota),
             }, ],
           })
           this.optionpie22 = ({
             tooltip: {
               trigger: "item",
               formatter: "{a} <br/>{b}: {c} ({d}%)",
             },

             graphic: {
               //图形中间图片
               elements: [{
                 type: "image",
                 style: {
                   image: require("../assets/images/index/pie-r.png"), //你的图片地址
                   width: 83,
                   height: 88,
                 },

                 left: "46",
                 top: "50",
               }, ],
             },

             legend: {
               orient: "vertical",
               right: "20%",
               icon: "circle",

               textStyle: {
                 //图例文字的样式
                 color: "#fff",
                 fontSize: 14,
               },


               //  formatter: function(params) {
               //             var legendIndex = 0;
               //             data.forEach(function (v, i) {
               //                 if (v.name == params) {
               //                     legendIndex = i;
               //                 }
               //             });
               //             return params + " " + data[legendIndex].value;
               //         },

             },

             series: [{
               name: "产品类目占比",
               type: "pie",
               radius: ["70%", "85%"],
               center: ["22%", "50%"],
               avoidLabelOverlap: false,
               label: {
                 show: false,
                 position: "center",
               },
               emphasis: {},
               itemStyle: {
                 //通常情况下：
                 normal: {
                   color: function (params) {
                     var colorList = [
                       ["#24fc2d", "#000000"],
                       ["#2262bf", "#000000"],
                       ["#7647c2", "#000000"],
                       ["#a4233d", "#000000"],
                       ["#964e14", "#000000"],
                       ["#d9c717", "#000000"],
                       ["#47c3ce", "#000000"],
                     ];
                     var colorItem = colorList[params.dataIndex];
                     return new ECharts.graphic.LinearGradient(
                       0,
                       0,
                       1,
                       0,
                       [{
                           offset: 0,
                           color: colorItem[0],
                         },
                         {
                           offset: 1,
                           color: colorItem[1],
                         },
                       ],
                       false
                     );
                   },
                 },
               },

               labelLine: {
                 show: false,
               },
               data: info.map(item => ({ value: item.quota, name: item.name }))
             }, ],
           })
         })
      },
      getyjcustomercrowd() {
        this.$api.get('/yongjia-biz/yjcustomercrowd/page?current=1&size=7')
         .then(({ data }) => {
           const info = data.records || []
           this.optionpie11 = ({
             tooltip: {
               trigger: "item",
               formatter: "{a} <br/>{b}: {c} ({d}%)",
             },

             graphic: {
               //图形中间图片
               elements: [{
                 type: "image",
                 style: {
                   image: require("../assets/images/index/pie-r.png"), //你的图片地址
                   width: 83,
                   height: 88,
                 },

                 left: "46",
                 top: "50",
               }, ],
             },

             legend: {
               orient: "vertical",
               right: "20%",
               icon: "circle",

               textStyle: {
                 //图例文字的样式
                 color: "#fff",
                 fontSize: 14,
               },


               //  formatter: function(params) {
               //             var legendIndex = 0;
               //             data.forEach(function (v, i) {
               //                 if (v.name == params) {
               //                     legendIndex = i;
               //                 }
               //             });
               //             return params + " " + data[legendIndex].value;
               //         },

             },

             series: [{
               name: "客户人群占比",
               type: "pie",
               radius: ["70%", "85%"],
               center: ["22%", "50%"],
               avoidLabelOverlap: false,
               label: {
                 show: false,
                 position: "center",
               },
               emphasis: {},
               itemStyle: {
                 //通常情况下：
                 normal: {
                   color: function (params) {
                     var colorList = [
                       ["#24fc2d", "#000000"],
                       ["#2262bf", "#000000"],
                       ["#7647c2", "#000000"],
                       ["#a4233d", "#000000"],
                       ["#964e14", "#000000"],
                       ["#d9c717", "#000000"],
                       ["#47c3ce", "#000000"],
                     ];
                     var colorItem = colorList[params.dataIndex];
                     return new ECharts.graphic.LinearGradient(
                       0,
                       0,
                       1,
                       0,
                       [{
                           offset: 0,
                           color: colorItem[0],
                         },
                         {
                           offset: 1,
                           color: colorItem[1],
                         },
                       ],
                       false
                     );
                   },
                 },
               },

               labelLine: {
                 show: false,
               },
               data: info.map(item => ({ value: item.crowdQuantity, name: item.ageGroup }))
             },
           ],
           })
         })
      },
      getObjByMenu() {
        this.$api.get('/yongjia-biz/yjshowdata/getObjByMenu/transaction')
         .then(({ data }) => {
           this.baseInfo= {
             countyTurnover: data.county_turnover.value, // 全县网络销售额
             countyTurnoverIncrease: data.county_turnover_increase.value, // 全县网络销售额同比增长
             countyProduce: data.county_produce.value, // 全县农产品网络零售额
             countyProduceIncrease: data.county_produce_increase.value, // 全县农产品网络零售额同比增长
             shopNew: data.shop_new.value,
             productNew: data.product_new.value,
             shopNum: data.shop_num.value,
             productNum: data.product_num.value,
             averagePrice: data.average_price.value,
           }
         })
      },
      getyjshop() {
        this.$api.get('/yongjia-biz/yjshop/page?current=1&size=20')
         .then(({ data }) => {
           const info = data.records || []
           this.List = info.map(item => ({ name: item.name, type: item.type, link: item.link  }))
           this.List3 = info.filter((item, idx) => idx < 10).map(item => ({ id: item.id, name: item.name, num: item.amount }))
         })
      },
      getyjgoods() {
        this.$api.get('/yongjia-biz/yjgoods/page?current=1&size=10')
         .then(({ data }) => {
           const info = data.records || []
           this.List2 = info.map(item => ({ id: item.id, name: item.name, num: item.orderNum }))
         })
      },
      changeCharts1(e) {
        switch (e) {
          case 0:
            this.chartTitle1 = '客户人群占比'
            break;
          case 1:
            this.chartTitle1 = '产品类目占比'
            break;
          default:
            break;
        }
      },
      close1() {
        this.showBoxes1 = false
      },
      close2() {
        this.showBoxes2 = false
      },
      close3() {
        this.showBoxes3 = false
      },
      close4() {
        this.showBoxes4 = false
      },
      showBox(e) {
        switch (e) {
          case 1:
            this.showBoxes1 = true
            break;
          case 2:
            this.showBoxes2 = true
            break;
          case 3:
            this.showBoxes3 = true
            break;
          case 4:
            this.showBoxes4 = true
            break;

          default:
            break;
        }
      },
      gotoHome() {
        this.$router.push({
          path: '/'
        })
      },
      openShop(e) {
        console.log(e.target.id, 'e.target.id');
        if (e.target.id) {
          window.open(e.target.id, '_blank')
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "../assets/css/pubilc.css";
  @import "../assets/css/font.css";

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1920px;
    height: 1080px;
    padding: 0 30px;
    box-sizing: border-box;
    // background: url("../assets/images/index/bg.png") no-repeat center #000;
    video {
      position: absolute;
      z-index: 0;
    }

    .header {
      width: 1917px;
      height: 167px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -958px;
      z-index: 0;
      background: url("../assets/images/index/header-bg.png") no-repeat center;

      h1 {
        font-size: 40px;
        font-family: PangMenZhengDao;
        font-weight: bold;
        color: #ffffff;
        margin-top: 32px;
        text-align: center;
        text-shadow: 0 0 0.2em #fff, -0 -0 0.2em #065abe;
      }
    }

    .footer {
      width: 1075px;
      height: 59px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -537px;
      z-index: 0;
      background: url("../assets/images/index/footer-bg.png") no-repeat;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 30px;
        font-family: PangMenZhengDao;
        font-weight: 400;
        color: #ffffff;
        text-shadow: 0 0 0.2em #fff, -0 -0 0.2em #065abe;
      }
    }
  }

  .column392 {
    width: 392px;
    margin-top: 75px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 80px;
  }

  .column-item {
    width: 100%;

    .column-item-title {
      width: 100%;
      height: 60px;

      h4 {
        line-height: 60px;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .colour {
      tbody {
        tr {
          &:nth-child(1) {
            .num {
              color: #e5772e;
            }
          }

          &:nth-child(2) {
            .num {
              color: #f19e6c;
            }
          }

          &:nth-child(3) {
            .num {
              color: #f1cd6c;
            }
          }
        }
      }
    }

    .table {
      width: 100%;

      .table-tbody {
        height: 200px;
        overflow: hidden;
      }

      .table-head-tr,
      .table-tbody-tr {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        text-align: center;
        height: 33px;
        line-height: 33px;

        &:nth-child(even) {
          background: url("../assets/images/index/hover-bg.png") no-repeat;
        }

      }

      .row1 {
        width: 70%;
      }

      .row2 {
        width: 30%;
      }

      .table-head-th {
        font-size: 20px;
        font-weight: bold;
        color: #556f95;
      }

      .table-head-td {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 20px;
      }
    }


    .table1 {
      width: 100%;

      .table-tbody {
        height: 200px;
        overflow: hidden;
      }

      .table-head-tr,
      .table-tbody-tr {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        text-align: center;
        height: 33px;
        line-height: 33px;

        &:nth-child(even) {
          background: url("../assets/images/index/hover-bg.png") no-repeat;
        }

        &:nth-child(1) {
          .row1 {
            color: #e5772e;
          }
        }

        &:nth-child(2) {
          .row1 {
            color: #f19e6c;
          }
        }

        &:nth-child(3) {
          .row1 {
            color: #f1cd6c;
          }
        }
      }

       .row1 {
        width: 20%;
      }

      .row2 {
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
      }

      .row3 {
        width: 30%;
      }

      .table-head-th {
        font-size: 20px;
        font-weight: bold;
        color: #556f95;
      }

      .table-head-td {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }

  .date {
    background: url("../assets/images/index/date-icon.png") no-repeat left center;
    height: 28px;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
  }

  .charts1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    div {
      margin-top: 19px;
    }

    .num {
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
    }

    .charts1-item-title {
      font-size: 14px;
      font-weight: bold;
      color: #64cdf9;
      opacity: 0.75;
    }
  }

  .charts2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    .charts2-item {
      width: 22%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      div {
        .num {
          font-size: 28px;
          font-weight: bold;
          color: #00e4ff;
        }

        .charts1-item-title {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }

  .btn-box {
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0px;
  }

  @keyframes bounce-down {
     25% {transform: translateY(-10px);}
     50%, 100% {transform: translateY(0);}
     75% {transform: translateY(10px);}
  }
  .column850 {
    .charts1-item {
      img {
        animation: bounce-down 3s linear infinite;
      }
    }
  }

  .column850 {
    width: 850px;
    position: absolute;
    left: 50%;
    bottom: 100px;
    margin-left: -425px;
  }

  .column1000 {
    width: 1000px;
    position: absolute;
    left: 50%;
    top: 130px;
    margin-left: -500px;
  }

  .monitor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .monitor-item {
      width: 50%;
      margin: 10px 0;
      .num {
        font-size: 26px;
        line-height: 26px;
        font-weight: bold;
        span {
          font-size: 12px;
          font-weight: normal;
        }
      }

      .title {
        font-size: 14px;
        font-weight: bold;
        color: #64cdf9;
      }
    }
  }

  .charts {
    .echarts {
      width: 100%;
      height: 180px;
    }
  }


  .column1050 {
    width: 1050px;
    position: absolute;
    left: 50%;
    top: 240px;
    margin-left: -525px;
  }

  .popup-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .popup-box-item {
      width: 520px;
      height: 331px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      position: relative;
      margin-bottom: 10px;

      .close-box {
        width: 17px;
        height: 17px;
        background: url('../assets/images/index/close.png') no-repeat;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 9999;
        transition: 1s;
        -webkit-transition: 1s;

        &:hover {
          cursor: pointer;
          transform: rotate(180deg);
        }
      }

      .charts {
        width: 100%;
        height: 331px;
        overflow: hidden;

        .echarts {
          width: 520px;
          height: 331px;
        }
      }
    }
  }


  .chartsList {
    width: 100%;
    height: 180px;

    .el-carousel__item {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 180px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      height: 180px;

      .echarts {
        width: 392px;
        height: 180px;
      }
    }

    .el-carousel__item:nth-child(2n+1) {
      height: 180px;

      .echarts {
        width: 392px;
        height: 180px;
      }
    }

  }

  // 图标切换效果 修改指示器样式

  ::v-deep .el-carousel__button {
    background: url('../assets/images/index/button-icon.png') no-repeat center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }


  .seamless-warp {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .Carousel_li {
    display: flex;
    padding-right: .5%;
    width: 100%;
    height: 240px;
    line-height: 240px;
    background-size: 100% !important;
    color: #BDF3F6;
    margin-bottom: 0.5208333333333334vw;
    font-size: 0.7291666666666667vw;

    .title {
      padding: 0 1% 0 4%;
      width: 21%;

    }

    .ovr_h {
      width: 51%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
